<template>
  <div class="container is-fluid">
    <div class="columns is-centered is-variable is-4">
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-primary">{{ $ml.get('dashboard_bets_count') }}</div>
          <div class="body">
            <div class="side-icon text-primary">
              <b-icon icon="star"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ new Intl.NumberFormat().format(this.$store.getters['auth/user'].dashboard.totalBets) }}</span>
              <span style="padding: 0 3px">/</span>
              <span :title="$ml.get('dashboard_today_title')" class="today">{{
                  new Intl.NumberFormat().format(this.$store.getters['auth/user'].dashboard.bets)
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-info">{{ $ml.get('dashboard_turnover') }}</div>
          <div class="body">
            <div class="side-icon has-text-info">
              <b-icon icon="reload"></b-icon>
            </div>
            <div class="side-info">
              <span><Money currency="EUR" :subunits=true
                           v-bind:amount="this.$store.getters['auth/user'].dashboard.totalTurnover"/></span>
              <span style="padding: 0 3px">/</span>
              <span :title="$ml.get('dashboard_today_title')" class="today"><Money :subunits=true currency="EUR"
                                                            v-bind:amount="this.$store.getters['auth/user'].dashboard.turnover"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-success">{{ $ml.get('dashboard_profit') }}</div>
          <div class="body">
            <div class="side-icon has-text-success">
              <b-icon icon="currency-usd"></b-icon>
            </div>
            <div class="side-info">
              <span><Money :subunits=true currency="EUR"
                           v-bind:amount="this.$store.getters['auth/user'].dashboard.totalProfit"/></span>
              <span style="padding: 0 3px">/</span>
              <span :title="$ml.get('dashboard_today_title')" class="today"><Money currency="EUR"
                                                            v-bind:amount="this.$store.getters['auth/user'].dashboard.profit"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-warning">ROI</div>
          <div class="body">
            <div class="side-icon has-text-warning">
              <b-icon icon="finance"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ this.$store.getters['auth/user'].dashboard.totalRoi }} %</span>
              <span style="padding: 0 3px">/</span>
              <span title="За сегодня" class="today">{{ this.$store.getters['auth/user'].dashboard.roi }} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-2">
        <div class="dash-plate box" style="height: 200px">
          <div class="header">{{ $ml.get('dashboard_accounts') }}</div>
          <div class="body">
            <div class="side-icon has-text-success">
              <b-icon icon="account-check"></b-icon>
              <span>ONLINE</span>
            </div>
            <div class="side-info">
              {{ new Intl.NumberFormat().format(this.$store.getters['auth/user'].dashboard.roomsOnline) }}
            </div>
          </div>
          <div class="body">
            <div class="side-icon has-text-warning">
              <b-icon icon="account-clock"></b-icon>
              <span>OFFLINE</span>
            </div>
            <div class="side-info">
              {{ new Intl.NumberFormat().format(this.$store.getters['auth/user'].dashboard.roomsOffline) }}
            </div>
          </div>
          <div class="body">
            <div class="side-icon has-text-danger">
              <b-icon icon="account-alert"></b-icon>
              <span>LIMITED</span>
            </div>
            <div class="side-info">
              {{ new Intl.NumberFormat().format(this.$store.getters['auth/user'].dashboard.roomsLimited) }}
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <ul class="notifications box" v-if="this.$store.getters['auth/user'].notifications.length > 0"
            style="height: 200px; overflow-x: hidden; overflow-y: auto">
          <li v-for="notification in this.$store.getters['auth/user'].notifications">
            <b-icon icon="bell" class="text-warning" v-if="notification.params.type === 'NEUTRAL'"></b-icon>
            <b-icon icon="checkbox-marked-circle" class="text-success"
                    v-if="notification.params.type === 'SUCCESS'"></b-icon>
            <b-icon icon="alert" class="text-danger" v-if="notification.params.type === 'WARNING'"></b-icon>
            <div class="time">{{ notification.created_at_hr }}</div>
            <div class="text">{{ notification['text_' + $ml.current] }}</div>
            <div class="action">
              <b-button size="is-small" type="is-warning" outlined @click="delete_notification(notification.id)">
                <b-icon icon="delete" size="is-small"></b-icon>
              </b-button>
            </div>
          </li>
        </ul>
        <div class="dash-plate box" style="height: 200px"
             v-if="this.$store.getters['auth/user'].notifications.length === 0">
          <i>{{ $ml.get('dashboard_no_notifications') }}</i>
        </div>
      </div>
    </div>
    <div class="columns is-centered" v-if="showGraph">
      <div class="column is-8">
        <div class="dash-plate box">
          <ProfitGraph
              v-if="showGraph"
              :chartdata="profitData"
              :options="profitOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Money from "@/components/Money";
import Vue from "vue";
import ProfitGraph from "@/components/ProfitGraph";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      showGraph: false,
      profitData: null,
      profitOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              // beginAtZero: true
            }
          }]
        }
      },
    }
  },
  components: {ProfitGraph, Money},
  methods: {
    delete_notification: function (id) {
      Vue.prototype.$http.delete(process.env.VUE_APP_API_HOST + `profile/delete_notification?id=${id}`).then(resp => {
        let user = this.$store.getters['auth/user']
        user.notifications = user.notifications.filter(item => item.id !== id)
        this.$store.commit('auth/user', user)
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      })
    },
  },
  async mounted() {
    Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `profile/index`).then(resp => {
      this.profitData = {
        labels: resp.data.result.profitDates,
        datasets: [
          {
            borderColor: '#228BE6',
            borderWidth: 3,
            backgroundColor: 'rgba(0,0,0,0)',
            pointBackgroundColor: '#fff',
            pointBorderColor: '#1864AB',
            pointBorderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: '#1864AB',
            data: resp.data.result.profitStat,
            tension: 0
          }
        ]
      }
      if (resp.data.result.profitDates.length > 0) {
        this.showGraph = true
      }
    })
  },
}
</script>

<style scoped>

</style>